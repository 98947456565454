<template>
  <div>
    <a-table
        :columns="tableList"
        :pagination="pagination"
        :data-source="list"
        :rowKey="(record, index) => index"
        @change="changePage"
    >
<!--   关联关系   -->
      <div slot="itemTieUpSlot" slot-scope="row">
        <a-tag
            color="blue"
            class="tag-class"
            v-for="item in row.subList"
            :key="item.id">{{ item.relCoinKindItemName + '·' + item.relCoinVersionName }}</a-tag>
      </div>
<!--   操作   -->
      <div slot="itemActionSlot" slot-scope="row">
        <div>
          <a-button
              class="mb-5"
              size="small"
              type="primary"
              @click="handleEditor(row)"
          >编辑关联</a-button>
        </div>
        <div>
          <a-button
              class="mt-5"
              size="small"
              type="danger"
              @click="handleDelete(row)"
          >删除关联</a-button>
        </div>
      </div>
    </a-table>
    <EditorTieUpPopup ref="editorTieUpPopupEl" @success="handleSuccess"/>
  </div>
</template>
<script>
import {kaiYuanCoinFrontListTableColumns} from "@/views/cmsPage/versionManage/kaiYuanCoinByFrontManage/_data"
import EditorTieUpPopup from "@/views/cmsPage/versionManage/kaiYuanCoinByFrontManage/_components/EditorTieUpPopup"
import {deleteKaiYuanByFrontList} from "@/views/cmsPage/versionManage/kaiYuanCoinByFrontManage/_apis"

export default {
  props: ['pagination', 'list'],
  components: {EditorTieUpPopup},
  data() {
    return {
      tableList: kaiYuanCoinFrontListTableColumns
    }
  },
  methods: {
    /** 编辑当前关联 */
    handleEditor(row) {
      this.$refs.editorTieUpPopupEl.show(row)
    },
    /** 删除关联 */
    async handleDelete(row) {
      await this.$refs.editorTieUpPopupEl.show(row, 'delete')
    },
    /** 翻页 */
    changePage(page) {
      this.$emit("changePage", page);
    },
    /** 成功 */
    handleSuccess() {
      this.$emit('success')
    }
  }
}
</script>
<style lang="scss" scoped>
.tag-class {
  margin-bottom: 5px;
}
</style>