<template>
  <a-modal
      v-model="showModal"
      :width='1000'
      :title="editorTitle"
      ok-text="确认"
      cancel-text="取消"
      @cancel="handleCanCelEditor"
      @ok="handleConfirmEditor"
  >
    <div class="modal-content">
      <a-form-model v-bind="layout" ref="ruleForm">
        <a-form-model-item label='搜索面文' prop='degree'>
          <a-select
              :dropdownMatchSelectWidth="false"
              :filter-option="untils.filterOption"
              allowClear
              showSearch
              @search='getKaiYuanCoinFrontTextList'
              size="small"
              class="w-300 mb-5"
              v-model="params.coinVersionId"
              placeholder="请搜索面文"
          >
            <a-select-option
                v-for="item of kaiYuanCoinFrontTextList"
                :key="item.id"
                :value="item.id"
            >{{ item.coinKindVersionName }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label='选择小币种' prop='degree' v-if="params.coinVersionId">
          <a-select
              style="width: 150px; margin: 5px"
              size="small"
              v-model="littleCoinId"
              placeholder="小币种"
              allowClear
              showSearch
              @change="handleChangeLittleCoinCoinId"
          >
            <a-select-option
                v-for="item of searchLittleCoins"
                :key="item.id"
                :value="item.id"
            >{{ item.coinKindItemName }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label='选择版别' prop='degree' v-if="littleCoinId">
          <div class="selected-box" v-if="versionList && versionList.length">
            <div class="selected-top-btn">
              <a-button size="small" type="primary" @click="handleSelectedAll">全选</a-button>
              <a-button class="ml-20" size="small" type="danger" @click="handleUnSelectedAll">取消全选</a-button>
            </div>
            <div class="selected-list">
              <div
                  class="selected-item"
                  :class="item.selected ? 'active-selected' : ''"
                  v-for="item in versionList"
                  :key="item.relCoinVersionId"
                  @click="handleCHangeItemVersion(item)"
              >{{item.name}}</div>
            </div>
          </div>
          <div v-else class="flex-center-center w-100-w">暂无版别~</div>
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-modal>
</template>

<script>
import untils from "@/untils";
import {
  addOrEditorKaiYuanByFrontList,
  getKaiYuanCoinFrontTextListApi
} from "@/views/cmsPage/versionManage/kaiYuanCoinByFrontManage/_apis"

export default {
  data() {
    return {
      untils,
      showModal: false,
      editorTitle: '新增关联',
      kaiYuanCoinFrontTextList: [],
      layout:{
        labelCol: { span: 4 },//标签占5份  共24份
        wrapperCol: { span:20 },
      },
      params: {
        coinKindId: 1,
        coinKindItemId: 749,
        coinVersionId: undefined,
        subList: []
      },
      // 小币种列表
      searchLittleCoins: [],
      // 小币种ID
      littleCoinId: undefined,
      // 版别数组
      versionList: [],
      // 版别选择的ID数组
      selectedVersionIdList: []
    };
  },
  methods: {
    /** 确定编辑 */
    async handleConfirmEditor() {
      if (!this.params.coinVersionId) return this.$message.warn('面文不能为空')
      if (!this.littleCoinId) return this.$message.warn('请选择小币种')
      let temp = []
      this.versionList.forEach(el => {
        if (el.selected) {
          temp.push({
            relCoinKindId: 1,
            relCoinKindItemId: this.littleCoinId,
            relCoinVersionId: el.relCoinVersionId,
          })
        }
      })
      if (temp.length < 1) return this.$message.warn('版别不能为空，请选择')
      this.params.subList = temp
      this.$loading.show()
      const res = await addOrEditorKaiYuanByFrontList(this.params)
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.info('添加成功')
      this.$emit('success')
      this.showModal = false
    },
    async show() {
      this.$loading.show()
      await this.getKaiYuanCoinFrontTextList()
      await this.searchLittleCoin()
      this.$loading.hide()
      this.showModal = true;
    },
    /** 取消编辑 */
    handleCanCelEditor() {
      this.params = {
        coinKindId: 1,
            coinKindItemId: 749,
            coinVersionId: undefined,
            subList: []
      }
      this.searchLittleCoins = []
      this.littleCoinId = undefined
      this.versionList = []
      this.selectedVersionIdList = []
      this.showModal = false
    },
    /** 获取正面面文列表 */
    async getKaiYuanCoinFrontTextList(inner) {
      const res = await getKaiYuanCoinFrontTextListApi({
        versionName: inner,
        pageNum: 1,
        pageSize: 10
      })
      if (res.status !== '200') return
      this.kaiYuanCoinFrontTextList = res.data.records
    },
    /** 搜索小币种 */
    async searchLittleCoin() {
      const res = await this.axios("/dq_admin/kinditem/list", {
        params: { coinId: 1, pageSize: 500},
      });
      let { records, } = res.data
      this.searchLittleCoins = records.filter(el => el.id !== 749);
    },
    /** 选择小币种之后搜索有多少个版别 */
    async handleChangeLittleCoinCoinId() {
      this.$loading.show()
      const res = await this.axios("/dq_admin/kinditemversion/list2", {
        params: {
          pageNum: 1,
          pageSize: 1000,
          coinId: 1,
          coinItemId: this.littleCoinId
        },
      });
      this.$loading.hide()
      if (res.status !== '200') return
      const { records } = res.data;
      this.versionList = records.map(el => {
        return {relCoinVersionId: el.id, name: el.coinKindVersionName, selected: false}
      })
    },
    /** 全选 */
    handleSelectedAll() {
      this.versionList.forEach(el => {
        el.selected = true
      })
      this.$forceUpdate()
    },
    /** 取消全选 */
    handleUnSelectedAll() {
      this.versionList.forEach((el => {
        el.selected = false
      }))
      this.$forceUpdate()
    },
    /** 改变当前选中的数据 */
    handleCHangeItemVersion(item) {
      this.versionList.forEach(el => {
        if (el.relCoinVersionId === item.relCoinVersionId) {
          el.selected = !el.selected
        }
      })
      this.$forceUpdate()
    }
  }
};
</script>

<style lang="scss" scoped>
.selected-item {
  padding: 3px 8px;
  height: 20px;
  font-size: 15px;
  font-weight: bold;
  color: #7c7a7a;
  background-color: #cccccc;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.active-selected {
  color: #fff !important;
  background-color: #2d8cf0 !important;
}
.selected-list {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.selected-top-btn {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.selected-box {
  width: 100%;
}
.modal-content {
  width: 100%;
}
</style>