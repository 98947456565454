import instance from "@/axios"

/** 修改d类送评物流信息 */
export const addOrEditorKaiYuanByFrontList = async (params) => {
    return instance({
        url: '/dq_admin/duibanKaiyuanFaceRel/addOrEditRel',
        method: 'POST',
        data: params
    })
}

/** 获取开元通宝正面面文列表 */
export const getKaiYuanCoinFrontTextListApi = async (params) => {
    return instance({
        url: '/dq_admin/duibanKaiyuanFaceRel/searchFaceVersion',
        method: 'GET',
        params: params
    })
}

/** 获取开元通宝正面面文关系列表数据 */
export const getKaiYuanCoinFrontTextTieUpListApi = async (params) => {
    return instance({
        url: '/dq_admin/duibanKaiyuanFaceRel/list',
        method: 'GET',
        params: params
    })
}

/** 删除列表数据 */
export const deleteKaiYuanByFrontList = async (params) => {
    return instance({
        url: '/dq_admin/duibanKaiyuanFaceRel/deleteById',
        method: 'GET',
        params: params
    })
}