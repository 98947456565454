var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { width: 1000, title: _vm.editorTitle },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              !_vm.deleteStatus
                ? _c(
                    "div",
                    [
                      _c(
                        "a-button",
                        { key: "back", on: { click: _vm.handleCanCelEditor } },
                        [_vm._v("取消")]
                      ),
                      _c(
                        "a-button",
                        {
                          key: "submit",
                          attrs: { type: "primary" },
                          on: { click: _vm.handleConfirmEditor }
                        },
                        [_vm._v("确定")]
                      )
                    ],
                    1
                  )
                : _c("div")
            ]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.showModal,
        callback: function($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal"
      }
    },
    [
      _c("div", { staticClass: "modal-content" }, [
        _c(
          "div",
          { staticClass: "modal-tag-list" },
          _vm._l(_vm.listData, function(item) {
            return _c(
              "div",
              { key: item.versionId, staticClass: "modal-tag-item" },
              [
                !item.isShowSelected
                  ? _c("div", { staticClass: "tag-item-box-text" }, [
                      _c("div", { staticClass: "item-text-box" }, [
                        _vm._v(
                          _vm._s(
                            item.relCoinKindItemName +
                              "·" +
                              item.relCoinVersionName
                          )
                        )
                      ]),
                      _c(
                        "div",
                        { staticClass: "item-icon" },
                        [
                          !_vm.deleteStatus
                            ? _c("a-icon", {
                                staticClass: "color-blue font-size-24 cur-pot",
                                attrs: { type: "edit" },
                                on: {
                                  click: function($event) {
                                    return _vm.handleEditorItem(item)
                                  }
                                }
                              })
                            : _vm._e(),
                          _c(
                            "a-popconfirm",
                            {
                              attrs: {
                                title:
                                  "确定删除【" +
                                  (item.relCoinKindItemName +
                                    "·" +
                                    item.relCoinVersionName) +
                                  "】关联？",
                                "ok-text": "Yes",
                                "cancel-text": "No"
                              },
                              on: {
                                confirm: function($event) {
                                  return _vm.handleDeleteItem(item)
                                }
                              }
                            },
                            [
                              _vm.deleteStatus
                                ? _c("a-icon", {
                                    staticClass:
                                      "color-red font-size-24 cur-pot ml-20",
                                    attrs: { type: "close-circle" }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  : _c("div", { staticClass: "tag-item-box-selected" }, [
                      _c(
                        "div",
                        { staticClass: "item-selected-box" },
                        [
                          _c(
                            "a-select",
                            {
                              staticClass: "w-100",
                              attrs: {
                                size: "small",
                                placeholder: "小币种",
                                allowClear: ""
                              },
                              on: {
                                change: function($event) {
                                  return _vm.handleChangeLittleCoinCoinId(item)
                                }
                              },
                              model: {
                                value: item.relCoinKindItemId,
                                callback: function($$v) {
                                  _vm.$set(item, "relCoinKindItemId", $$v)
                                },
                                expression: "item.relCoinKindItemId"
                              }
                            },
                            _vm._l(_vm.searchLittleCoins, function(smallCoin) {
                              return _c(
                                "a-select-option",
                                {
                                  key: smallCoin.id,
                                  attrs: { value: smallCoin.id }
                                },
                                [_vm._v(_vm._s(smallCoin.coinKindItemName))]
                              )
                            }),
                            1
                          ),
                          _c(
                            "a-select",
                            {
                              staticClass: "w-250 ml-5",
                              attrs: {
                                size: "small",
                                placeholder: "选择版别",
                                allowClear: ""
                              },
                              model: {
                                value: item.relCoinVersionId,
                                callback: function($$v) {
                                  _vm.$set(item, "relCoinVersionId", $$v)
                                },
                                expression: "item.relCoinVersionId"
                              }
                            },
                            _vm._l(_vm.versionList, function(version) {
                              return _c(
                                "a-select-option",
                                {
                                  key: version.id,
                                  attrs: { value: version.id }
                                },
                                [_vm._v(_vm._s(version.coinKindVersionName))]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c("div")
                    ])
              ]
            )
          }),
          0
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }