var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          columns: _vm.tableList,
          pagination: _vm.pagination,
          "data-source": _vm.list,
          rowKey: function(record, index) {
            return index
          }
        },
        on: { change: _vm.changePage },
        scopedSlots: _vm._u([
          {
            key: "itemTieUpSlot",
            fn: function(row) {
              return _c(
                "div",
                {},
                _vm._l(row.subList, function(item) {
                  return _c(
                    "a-tag",
                    {
                      key: item.id,
                      staticClass: "tag-class",
                      attrs: { color: "blue" }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          item.relCoinKindItemName +
                            "·" +
                            item.relCoinVersionName
                        )
                      )
                    ]
                  )
                }),
                1
              )
            }
          },
          {
            key: "itemActionSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "div",
                  [
                    _c(
                      "a-button",
                      {
                        staticClass: "mb-5",
                        attrs: { size: "small", type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.handleEditor(row)
                          }
                        }
                      },
                      [_vm._v("编辑关联")]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "a-button",
                      {
                        staticClass: "mt-5",
                        attrs: { size: "small", type: "danger" },
                        on: {
                          click: function($event) {
                            return _vm.handleDelete(row)
                          }
                        }
                      },
                      [_vm._v("删除关联")]
                    )
                  ],
                  1
                )
              ])
            }
          }
        ])
      }),
      _c("EditorTieUpPopup", {
        ref: "editorTieUpPopupEl",
        on: { success: _vm.handleSuccess }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }