
<template>
  <div class="content">
    <div class="top flex-sp-bt-center">
<!--   选择正面面文   -->
      <div>
        <a-select
            :dropdownMatchSelectWidth="false"
            :filter-option="untils.filterOption"
            allowClear
            showSearch
            @search='getKaiYuanCoinFrontTextList'
            size="small"
            class="w-300 mb-5"
            v-model="params.versionId"
            placeholder="请选择面文"
        >
          <a-select-option
              v-for="item of kaiYuanCoinFrontTextList"
              :key="item.id"
              :value="item.id"
          >{{ item.coinKindVersionName }}</a-select-option>
        </a-select>
        <a-button
            @click="search"
            class="ml-5 mb-5"
            type="primary"
            size="small"
        >搜索</a-button>
      </div>
      <a-button
          class="ml-5 mb-5"
          size="small"
          @click="handleAdd()"
      >新增关联</a-button>
    </div>
    <div class="table-content">
      <TableList
          :list="listData"
          :pagination="pagination"
          @changePage="handleChangePage"
          @success="getList"
      />
    </div>
    <AddTieUpPopup ref="addTieUpPopupEl" @success="getList"/>
  </div>
</template>
<script>
import TableList from "@/views/cmsPage/versionManage/kaiYuanCoinByFrontManage/_components/TableList"
import untils from "@/untils"
import {
  getKaiYuanCoinFrontTextListApi,
  getKaiYuanCoinFrontTextTieUpListApi
} from "@/views/cmsPage/versionManage/kaiYuanCoinByFrontManage/_apis"
import AddTieUpPopup from "@/views/cmsPage/versionManage/kaiYuanCoinByFrontManage/_components/AddTieUpPopup"

export default {
  components: {TableList, AddTieUpPopup},
  data() {
    return {
      untils,
      listData: [],
      params: {
        pageSize: 3,
        pageNum: 1,
        versionId: undefined,
      },
      pagination: {
        total: 0,
        current: 1,
        pageSize: 3,
        showTotal: (total) => `共${total}条`,
      },
      kaiYuanCoinFrontTextList: [],
    }
  },
  async created() {
    await this.getKaiYuanCoinFrontTextList()
    await this.getList()
  },
  methods: {
    /** 获取列表数据 */
    async getList() {
      this.$loading.show()
      const res = await getKaiYuanCoinFrontTextTieUpListApi()
      this.$loading.hide()
      if (res.status !== '200') return;
      this.listData = res.data.records
      this.pagination.total = res.data.total
    },
    /** 翻页 */
    handleChangePage(page) {
      const { current } = page
      this.$set(this.pagination, "current", current)
      this.$set(this.params, "pageNum", current)
      this.getList()
    },
    /** 搜索 */
    search() {
      this.$set(this.pagination, "current", 1)
      this.$set(this.params, "pageNum", 1)
      this.getList()
    },
    /** 获取正面面文列表 */
    async getKaiYuanCoinFrontTextList(inner) {
      const res = await getKaiYuanCoinFrontTextListApi({
        versionName: inner,
        pageNum: 1,
        pageSize: 10
      })
      if (res.status !== '200') return
      this.kaiYuanCoinFrontTextList = res.data.records
    },
    /** 新增关联 */
    handleAdd() {
      this.$refs.addTieUpPopupEl.show()
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  .top-search {
    display: flex;
    align-items: center;
  }
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}

.top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.select-all-text {
  margin-left: 20px;
  margin-right: 20px;
}
.bgf {
  background-color: #fff !important;
  border: 1px solid #000c17 !important;
  color: #000c17 !important;
  margin-left: 20px !important;
}

.pagination {
  text-align: right;
  margin-top: 10px;
}
.ml-5 {
  margin-left: 5px;
}
</style>
