var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "div",
        { staticClass: "top flex-sp-bt-center" },
        [
          _c(
            "div",
            [
              _c(
                "a-select",
                {
                  staticClass: "w-300 mb-5",
                  attrs: {
                    dropdownMatchSelectWidth: false,
                    "filter-option": _vm.untils.filterOption,
                    allowClear: "",
                    showSearch: "",
                    size: "small",
                    placeholder: "请选择面文"
                  },
                  on: { search: _vm.getKaiYuanCoinFrontTextList },
                  model: {
                    value: _vm.params.versionId,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "versionId", $$v)
                    },
                    expression: "params.versionId"
                  }
                },
                _vm._l(_vm.kaiYuanCoinFrontTextList, function(item) {
                  return _c(
                    "a-select-option",
                    { key: item.id, attrs: { value: item.id } },
                    [_vm._v(_vm._s(item.coinKindVersionName))]
                  )
                }),
                1
              ),
              _c(
                "a-button",
                {
                  staticClass: "ml-5 mb-5",
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.search }
                },
                [_vm._v("搜索")]
              )
            ],
            1
          ),
          _c(
            "a-button",
            {
              staticClass: "ml-5 mb-5",
              attrs: { size: "small" },
              on: {
                click: function($event) {
                  return _vm.handleAdd()
                }
              }
            },
            [_vm._v("新增关联")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-content" },
        [
          _c("TableList", {
            attrs: { list: _vm.listData, pagination: _vm.pagination },
            on: { changePage: _vm.handleChangePage, success: _vm.getList }
          })
        ],
        1
      ),
      _c("AddTieUpPopup", {
        ref: "addTieUpPopupEl",
        on: { success: _vm.getList }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }